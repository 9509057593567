<template>
  <div v-bind="attrs">
  <label v-if="props.label" class="form-label" :for="fullId">{{ props.label }}</label> 
  <InlineLoaderTinyMCE :is-loading="isLoading" info="Editor wird geladen">
    <template v-if="props.inline">
    <div :id="fullId" :contenteditable="!disabled" v-bind="attrs"
    class="editable-content"
    :class="{ 'editable-border': !disabled }">
    >{{ props.modelValue }}</div>
  </template>
  <template v-else>
    <textarea :id="fullId" :disabled="disabled" v-bind="attrs">{{ props.modelValue }}</textarea>
  </template>
  </InlineLoaderTinyMCE>
  </div>
  <Modal ref="saveModal">
    <form>
          <div class="row">
            <div class="col-6">      
              <label :for="`client_${customId}`" class="form-label">Mandant</label>
              <Select
                :id="`client_${customId}`"
                v-model="saveModalInput.client_id"
                :options="clients"
                return-value="id"
                text="name"
                disabled
              />  
            </div>
            <div class="col-6">
              <label :for="`baustein_name_${customId}`" class="form-label">Baustein Name</label>
              <Input
                :id="`baustein_name_${customId}`"
                v-model="saveModalInput.name"
                return-value="id"
                text="name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">      
              <label :for="`categories_save_${customId}`" class="form-label">Kategorie</label>
              <Select 
                :id="`categories_save_${customId}`"
                v-model="saveModalInput.category_id"
                :options="saveCategories"
                return-value="id"
                text="name"
              />  
            </div>
          </div>
          
          <ModalFooter>      
        <Button @click="saveModal.close();" html-type="secondary"><Icon icon="fa-check" /> Schließen</Button>        
        <Button @click="saveSnippet" html-type="primary"><Icon icon="fa-check" /> Speichern</Button>
      </ModalFooter>
        </form>
  </Modal>
  <modal ref="loadModal">
    <div class="row">
      <div class="col-6">        
        <label for="categories" class="form-label">Baustein Kategorie</label>
              <Select
               @change="filierSnippets"
                id="categories"
                v-model="selectModalInput.category_id"
                :options="selectableCategories"
                return-value="id"
                text="name"
              />  
      </div>
      <div class="col-6">        
        <label for="category_client_id" class="form-label">Mandant</label>
              <Select
               @change="filterCategories"
                id="category_client_id"
                v-model="selectModalInput.client_id"
                :options="clients"
                return-value="id"
                text="name"
              />  
      </div>
    </div>  
    <h5 class="mt-4 text-center">Verfügbare Bausteine</h5>
    <Card>
      <div class="list-group">
        <div v-for="snippet in selectableSnippets" :key="snippet.id" class="list-group-item list-group-item-action">
  <div class="d-flex justify-content-between align-items-center snippet-row" @click="loadSnippet(snippet)">
    <span class="snippet-name">{{ snippet.name }}</span>
    <span class="snippet-category" v-if="selectModalInput.category_id === 0">{{ getCategoryName(snippet.category_id) }}</span>
    <div class="btn-group" role="group" @click.stop>             
      <ButtonCircle @click.prevent="loadSnippet(snippet)"><Icon icon="fa-plus" /></ButtonCircle>
      <ButtonCircle @click.prevent="deleteSnippet(snippet)" type="delete" v-if="snippet.client_id === admin.client_id"><Icon icon="fa-trash" /></ButtonCircle>
    </div>
  </div>
</div>
      </div>
    </Card>
    <ModalFooter></ModalFooter>
  </modal>
</template>
<script setup>



const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
  },
  init: {
    type: Object,
    default: () => ({}),
  },
  simpleMode: {
    type: Boolean,
    default: false
  },
  modalInstance: {
    default: null
  },
  inline: {
    type: Boolean,
    default: false
  },
  internalLoading: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  minHeight: {
    type: Number,
    default: 500
  }
});

import InlineLoaderTinyMCE from "~/components/Layout/InlineLoaderTinyMCE.vue";
const emit = defineEmits(['update:modelValue', 'loaded']);
const colorMode = useColorMode();
const clients = ref([]);
const saveModal = ref({});
const snippets = ref([]);
const selectableSnippets = ref([]);
const snippetsCategories = ref([]);
const saveCategories = ref([]);
const selectableCategories = ref([]);
const saveModalInput = ref ({});
const selectModalInput = ref ({});
const isLoading = ref(props.internalLoading);
const loadModal = ref({});
const customId = ref(props.id);
const disabled = ref(props.disabled);
const { data: admin } = useAuth();
const { $repository, toast } = useNuxtApp();
const darkMode = computed(() => {
  return colorMode.preference === "dark" ? true : false
}) 
let localModal = null;


const attrs = useAttrs();


const fullId = computed(() => {
  return `editor-${customId.value}`;
});

const toolbar = computed(() => {
    if (disabled.value) {
      return '';
    } else if (props.simpleMode) {
      return ' bold italic | alignleft aligncenter alignright bullist | code';
    } else {
      return 'undo redo restoredraft searchreplace|  bold italic | alignleft aligncenter alignright bullist | '
    }
})

// Define default TinyMCE configuration
const defaultInit = {
  plugins: 'link image code autosave fullscreen searchreplace quickbars insertdatetime lists advlist autoresize',
  autosave_interval: '5s',
  autosave_restore_when_empty: true,
  toolbar: toolbar.value,
  language: 'de',
  quickbars_selection_toolbar: 'bold italic | blocks | quicklink blockquote insertdatetime bullist',
  license_key: 'gpl',
  promotion: false,
  min_height: props.minHeight,
  autoresize_bottom_margin: 20,
  language_url: '/assets/js/tinymce/langs/de.js',  
  base_url: '/assets/js/tinymce', 
  suffix: '.min',
  menubar: props.simpleMode || disabled.value ? '': 'file edit view insert format tools table help',
  skin: darkMode.value ? 'oxide-dark' : 'oxide',
  readonly: disabled.value,
  inline: props.inline,
  content_css: darkMode.value ? 'dark' : 'default',
};
try {
  const tinymceRequest = await $repository.tinymce.getSnippets();
  if (tinymceRequest && tinymceRequest.success) {
    snippets.value = tinymceRequest.snippets;
  }
} catch (e) {
  await toast.showError("Whoops!", e);
}

try {
  const clientsRequest = await $repository.client.getClients();
  if (clientsRequest && clientsRequest.success) {
    clients.value = clientsRequest.clients;
    saveModalInput.value.client_id = admin.value.client_id;
  }
} catch (e) {
  await toast.showError("Whoops!", e);
}
try {
  const tinymceCategoryRequest = await $repository.tinymce.getSnippedCategories();
  if (tinymceCategoryRequest && tinymceCategoryRequest.success) {    
    saveCategories.value = tinymceCategoryRequest.categories.filter(category => category.client_id === admin.value.client_id);
    snippetsCategories.value = tinymceCategoryRequest.categories;
    clients.value.forEach(client => {
      snippetsCategories.value.unshift({ 
        id: 0, 
        name: "Alle", 
        client_id: client.id 
      });
    });
    selectModalInput.value.category_id = 0;
    selectModalInput.value.client_id = admin.value.client_id;
    filterCategories();
    filierSnippets();
  }
} catch (e) {
  await toast.showError("Whoops!", e);
}

const getCategoryName = (categoryId) => {
  const category = snippetsCategories.value.find(cat => cat.id === categoryId);
  return category ? category.name : 'Unknown Category'; // Return category name or a default message
};


function filierSnippets() {
  const categoryId = selectModalInput.value.category_id;
  const clientId = selectModalInput.value.client_id;
  selectableSnippets.value = snippets.value.filter(snippet => {
    if (categoryId === 0) {
      return snippet.client_id === clientId;
    } else {
      return snippet.category_id === categoryId && snippet.client_id === clientId;
    }
  });
}

function filterCategories() {
  const clientId = selectModalInput.value.client_id;
  selectableCategories.value = snippetsCategories.value.filter(category => {
    return category.client_id === clientId;
  })
  selectModalInput.value.category_id = 0;
  filierSnippets();
}


async function saveSnippet() {
  const name = saveModalInput.value.name;
  const client_id = admin.value.client_id;
  const category_id = saveModalInput.value.category_id;
  const existingSnippet = snippets.value.find(snippet => {
  return snippet.name.toLowerCase().trim() === name.toLowerCase().trim() &&  snippet.client_id === client_id && snippet.category_id === saveModalInput.value.category_id
}
);
  const update = existingSnippet ? true: false;
  let result = [];
  if (!update) {
    result = [];
    result.isConfirmed = true;
  } else {
    result = await toast.showConfirmation("Baustein", `Baustein mit dem Namen ${name} exsistiert bereits! Überschreiben?`);
  }
  if (result.isConfirmed) {
    const content = tinymce.get(fullId.value).getContent();

      const body = {
      content,
      name,
      client_id,
      category_id,
    }
    if (existingSnippet) {
      body.id = existingSnippet.id;
    }
    try {
      const saveSnippedRequest = await $repository.tinymce.saveSnipped(body, update);
        if (saveSnippedRequest && saveSnippedRequest.success) {
          saveModalInput.value.client_id = admin.value.client_id;
          saveModalInput.value.category_client_id = admin.value.client_id;
          saveModal.value.close();
          saveModalInput.value.name = '';
          saveModalInput.value.category_id = null;
          const clientId = selectModalInput.value.client_id;
          const snippet = saveSnippedRequest.snippet;

          const existingSnippetIndex = snippets.value.findIndex(existingSnippet => existingSnippet.id === snippet.id);
          const existingSelectableIndex = selectableSnippets.value.findIndex(existingSnippet => existingSnippet.id === snippet.id);
          const currentCategoryId = selectModalInput.value.category_id; 

          if (snippet.client_id === clientId) {
            if (existingSelectableIndex !== -1 && existingSnippetIndex !== -1) {
              snippets.value[existingSnippetIndex] = snippet;              
              selectableSnippets.value[existingSelectableIndex] = snippet;
            }
            else if(existingSnippetIndex !== -1) {   
              snippets.value[existingSnippetIndex] = snippet;           
            }
            else {
              snippets.value.push(snippet); 
              if (snippet.category_id === currentCategoryId || currentCategoryId === 0) {
                selectableSnippets.value.push(snippet);
              }
            }
          }          
          toast.showSuccess("Baustein erfolgreich gespeichert!");
        } else if (saveSnippedRequest && saveSnippedRequest.errors) {
            await toast.showError('Fehler!', "Inhalt darf nicht leer sein!");
        }
    } catch (e) {
    await toast.showError("Whoops!", e);
    }
  }
}

function loadSnippet(snippet) {
  tinymce.get(fullId.value).insertContent(snippet.content);
  loadModal.value.open();
}

async function deleteSnippet(snippet) {
  const result = await toast.showConfirmation("Löschen", "Wollen Sie den Baustein wirklich löschen?")
  if (result.isConfirmed) {
    try {
    const deleteSnipetRequest = await $repository.tinymce.deleteSnippet(snippet.id);
    if (deleteSnipetRequest && deleteSnipetRequest.success) {
      const deletedSnippetId = deleteSnipetRequest.snippet.id;
      const indexSelect = selectableSnippets.value.findIndex(snippet => snippet.id === deletedSnippetId);
      const indexMain = snippets.value.findIndex(snippet => snippet.id === deletedSnippetId);
      if (indexSelect !== -1) {
        selectableSnippets.value.splice(indexSelect, 1);
      }
      if (indexMain !== -1) {
        snippets.value.splice(indexMain, 1);
      }
      
      toast.showSuccess("Baustein gelöscht!");
    }
  }
  catch (e) 
  {
    await toast.showError("Whoops!", e);
  }
  }
}
const mergedInit = {
  ...defaultInit,
  ...props.init,
  toolbar: `${defaultInit.toolbar} ${props.init.additionalButtons || ''}`.trim(),
};
function initializeEditor() {
  if (typeof tinymce === 'undefined') {
    console.error('TinyMCE failed to load');
    return; // Exit the function if TinyMCE is not loaded
  }
  // Initialize TinyMCE
  tinymce.init({
    target: document.getElementById(fullId.value),
    ...mergedInit, 
    setup: (editor) => {
      const focusInHandler = function (e) {
      if (e.target.closest(".tox-dialog")) {
        e.stopImmediatePropagation();
      }
    };
      editor.ui.registry.addButton('saveSnippet', {
      icon: 'save',
      tooltip: 'Baustein speichern',
      onAction: () => {
        saveModal.value.open();
      }
      });
      editor.ui.registry.addButton('loadSnippet', {
      icon: 'upload',
      tooltip: 'Baustein laden',
      onAction: () => {
        loadModal.value.open();
      }
      });
      editor.on('change keyup', () => {
        emit('update:modelValue', editor.getContent());
      }); 
      editor.on('OpenWindow', function () {
        if (localModal) {
          document.addEventListener('focusin', focusInHandler, true);
        }
      });
  
      editor.on('CloseWindow', function () {
        if (localModal) {
          document.removeEventListener('focusin', focusInHandler, true);
        }
      });    

      editor.on('init', () => {
        isLoading.value = false;
        document.getElementById(fullId.value).style.visibility = "visible";
        editor.setContent(props.modelValue || '');
        emit('loaded');
      });
    }
  });
}
// Watch for changes in modelValue and update the TinyMCE content
watch(() => props.modelValue, (newValue) => {
  const activeEditor = tinymce.get(fullId.value); 
  if (activeEditor && newValue !== activeEditor.getContent()) {
    // Ensure editor is ready before updating the content
    activeEditor.setContent(newValue || '');
  }
});

onMounted(() => { 
  if (props.modalInstance) {
  localModal = props.modalInstance.getModalObject();
  }

  const existingScript = document.getElementById("unique");
  if (existingScript) {
    initializeEditor();
  } else {
    const script = document.createElement('script');
    script.id = "unique"
    script.src = '/assets/js/tinymce/tinymce.min.js';  // Path to TinyMCE in public folder
    script.onload = initializeEditor;
    document.head.appendChild(script);
  }
 
});

onBeforeUnmount(() => {
  onBeforeUnmount(() => {
  const editorInstance = tinymce.get(fullId.value);
  if (editorInstance) {
    tinymce.remove(editorInstance);
  }
});
});
</script>
<style scoped>

.list-group-item {
  transition: background-color 0.2s;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.snippet-row {
  cursor: pointer;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between title and category */
  align-items: center; /* Center items vertically */
}

.snippet-name {
  max-width: 10%;
  flex-grow: 1; 
  white-space: nowrap; 
}

.snippet-category {
  margin: 0 10px; 
}

.btn-group {
  display: flex; /* Ensure buttons are in a row */
}


/* MUSS IN MAIN STYLE */
.dark-mode .list-group-item {
  background-color: #343a40; /* Dark background */
  color: white; /* Light text */
}

.dark-mode .list-group-item:hover {
  background-color: #495057; /* Darker background on hover */
}
.icon-button .icon {
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Ensure it takes full height */
}



.editable-content {
  padding: 8px;
  min-height: 100px; /* Make it a bit more visible */
}

.editable-border {
  border: 1px solid #ccc; /* Light gray border to indicate it's editable */
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.editable-border:focus {
  border-color: #007bff; /* Change border color when focused (blue, for example) */
  outline: none; /* Remove default outline */
}

</style>